import Service from '@ember/service';
import {
    inject
  } from '@ember/service';
  import {
    task
  } from 'ember-concurrency';

export default Service.extend({
    firebase: inject(),

    openEmailWebsiteWithEvents: task(function* (events) {
      const ids = yield events.map(i => i.get('anmeldungen').map(j => j.get('termAnmeldung.teilnehmer.id'))).flat()
      this.get('openEmailWebsite').perform(ids)
    }),

    openEmailWebsiteWithTermanmeldung: task(function* (termAnmeldung) {
      const ids = yield [termAnmeldung.get('teilnehmer.id')]
      this.get('openEmailWebsite').perform(ids)
    }),

    openEmailWebsiteWithTermanmeldungen: task(function* (termAnmeldungen) {
      const ids = yield termAnmeldungen.map(i => i.get('teilnehmer.id'))
      this.get('openEmailWebsite').perform(ids)
    }),

    openEmailWebsiteWithAnmeldungen: task(function* (anmeldungen) {
      const ids = yield anmeldungen.map(i => i.get('termAnmeldung.teilnehmer.id'))
      this.get('openEmailWebsite').perform(ids)
    }),

    openEmailWebsiteWithAnmeldungenForOneEvent: task(function* (anmeldungen, eventID) {
      const ids = yield anmeldungen.map(i => i.get('id'))
      this.get('openEmailWebsite').perform(ids)
    }),

    openEmailWebsite: task(function* (ids) {
      let url = "https://www.vereinonline.org/BeachL_eV/?action=members_sendmailsuche&dialog=2"
      url += ids.map(id => '&check_'+id+'=1').toArray().join('')
      window.open(url, '_blank')
    }),
});
