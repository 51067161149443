import Controller from '@ember/controller';
import {
  task,
  all
} from 'ember-concurrency';

export default Controller.extend({
    queryParams: ['queryAbsagen'],
    searchProperties: ['vorname', 'nachname', 'email', 'absageGrund'],
    actions:{
      extendedSortKey(key){
        if(key == 'kurs:desc'){
          return ['lengthIncludedAnmeldungen:desc', 'lengthToBeDetermindAnmeldungen:desc', 'vorname', 'nachname'];
        }else if(key == 'kurs:asc'){
          return ['lengthIncludedAnmeldungen:asc', 'lengthToBeDetermindAnmeldungen:asc', 'vorname', 'nachname'];
        }else{
          return [key, 'vorname:asc', 'nachname:asc'];
        }
      }
    }
});
