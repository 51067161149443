import Component from '@ember/component';
import { computed }  from '@ember/object';
import {
  task
} from 'ember-concurrency';

export default Component.extend({
  tauschbar: computed('anmeldungen.@each.{status,lock}', function(){
    const an = this.get('anmeldungen').filterBy('lock', false)
    if(an.get('length') === 0) return false
    return an.filterBy('status', 'included').get('length') === an.get('length')/2
  }),
  tauschen: task(function* () {
    if(!this.get('tauschbar')) return
    const an = this.get('anmeldungen').filterBy('lock', false)
    const anIncluded = an.filterBy('status', 'included')
    const anExcluded = an.filter(i => i.get('status') !== 'included')
    anIncluded.forEach(i => i.set('statusPlusGrund', 'toBeDetermind'))
    anExcluded.forEach(i => i.set('statusPlusGrund', 'included'))
  }),
});
