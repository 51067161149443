import DS from 'ember-data';
import AutoSave from '../mixins/auto-save-model';
import IndicateSaveing from '../mixins/indicate-saving-model';
import {
  computed, observer
} from '@ember/object';
import moment from 'moment';
import {
  task
} from 'ember-concurrency';
import { once } from '@ember/runloop';

function createDateComputed(attr){
  return computed(attr, {
    get() {
      if(moment(this.get(attr)).valueOf() === 0) return ''
      return moment(this.get(attr)).format('YYYY-MM-DD');
    },
    set(key, value) {
      const m = moment(value, "YYYY-MM-DD")
      if(m.isValid()){
        this.set(attr, m.toDate())
      }else{
        this.set(attr, moment(0).toDate())
      }
      return value;
    }
  })
}

function createCalcDateComputed(attr){
  return computed(attr, attr+'DateType', attr+'DateRelativeTo', attr+'DateOffset', 'parent.start', 'term.{von,bis,anmeldestart,anmeldeschluss}', {
    get() {
      const dtype = this.get(attr+'DateType')
      if(dtype === 'relative'){
        if(this.get(attr+'DateRelativeTo') === "beforeStartAnmeldung"){
          if(moment(this.get('term.anmeldestart')).valueOf() === 0){
            return 0
          }else{
            return moment(this.get('term.anmeldestart')).add(this.get(attr+'DateOffset')*-1, 'weeks').toDate()
          }
        }else if(this.get(attr+'DateRelativeTo') === "afterStartAnmeldung"){
          if(moment(this.get('term.anmeldestart')).valueOf() === 0){
            return 0
          }else{
            return moment(this.get('term.anmeldestart')).add(this.get(attr+'DateOffset'), 'weeks').toDate()
          }
        }else if(this.get(attr+'DateRelativeTo') === "beforeEndAnmeldung"){
          if(moment(this.get('term.anmeldeschluss')).valueOf() === 0){
            return 0
          }else{
            return moment(this.get('term.anmeldeschluss')).add(this.get(attr+'DateOffset')*-1, 'weeks').toDate()
          }
        }else if(this.get(attr+'DateRelativeTo') === "afterEndAnmeldung"){
          if(moment(this.get('term.anmeldeschluss')).valueOf() === 0){
            return 0
          }else{
            return moment(this.get('term.anmeldeschluss')).add(this.get(attr+'DateOffset'), 'weeks').toDate()
          }
        }else if(this.get(attr+'DateRelativeTo') === "afterEndPeriode"){
          if(moment(this.get('term.bis')).valueOf() === 0){
            return 0
          }else{
            return moment(this.get('term.bis')).add(this.get(attr+'DateOffset'), 'weeks').toDate()
          }
        }else if(this.get(attr+'DateRelativeTo') === "beforeEndPeriode"){
          if(moment(this.get('term.bis')).valueOf() === 0){
            return 0
          }else{
            return moment(this.get('term.bis')).add(this.get(attr+'DateOffset')*-1, 'weeks').toDate()
          }
        }else if(this.get(attr+'DateRelativeTo') === "afterStartPeriode"){
          if(moment(this.get('term.von')).valueOf() === 0){
            return 0
          }else{
            return moment(this.get('term.von')).add(this.get(attr+'DateOffset'), 'weeks').toDate()
          }
        }else{
          if(moment(this.get('term.von')).valueOf() === 0){
            return 0
          }else{
            return moment(this.get('term.von')).add(this.get(attr+'DateOffset')*-1, 'weeks').toDate()
          }
        }
      }else if(dtype === 'parent'){
        if(!this.get('parent')) return 0
        return this.get('parent.start')
      }else{
        return this.get(attr)
      }
    },
    set(key, value) {
      const dtype = this.get(attr+'DateType')
      if(dtype === 'fixed'){
        this.set(attr, value)
      }
      return value;
    }
  })
}

function createDateUpdate(attr){
  return observer(attr+'DateType', attr+'DateRelativeTo', attr+'DateOffset', 'parent.start', 'term.{von,bis,anmeldestart,anmeldeschluss}', function(){
    once(this, attr+"ProcessUpdate")
  })
}

function createProcessDateUpdate(attr){
  return function(){
    if(this.get(attr) !== this.get(attr+"Computed")){
      this.set(attr, this.get(attr+"Computed"))
    }
  }
}

export default DS.Model.extend(AutoSave, IndicateSaveing, {
  term: DS.belongsTo('term', { async: true, inverse: 'processSteps' }),
  name: DS.attr('string'),
  text: DS.attr('string'),
  textSnippet: DS.attr('string'),
  type: DS.attr('string', { defaultValue: 'task' }),
  status: DS.attr('string', { defaultValue: 'Todo' }),
  start: DS.attr('fire-date'),
  end: DS.attr('fire-date'),
  date: DS.attr('fire-date'),
  dateRequiered: DS.attr('boolean'),
  reminder: DS.attr('fire-date'),
  startDateType: DS.attr('string', {defaultValue: 'relative'}), //fixed
  endDateType: DS.attr('string', {defaultValue: 'relative'}), //fixed
  reminderDateType: DS.attr('string', {defaultValue: 'parent'}),  //fixed, relative
  startDateRelativeTo: DS.attr('string', { defaultValue: 'beforeStartPeriode' }),
  endDateRelativeTo: DS.attr('string', { defaultValue: 'beforeStartPeriode' }),
  reminderDateRelativeTo: DS.attr('string', { defaultValue: 'beforeStartPeriode' }),
  startDateOffset: DS.attr('number'),
  endDateOffset: DS.attr('number'),
  reminderDateOffset: DS.attr('number'),
  url: DS.attr('string'),
  instructionUrl: DS.attr('string'),
  position: DS.attr('number'),
  startComputed: createCalcDateComputed('start'),
  endComputed: createCalcDateComputed('end'),
  reminderComputed: createCalcDateComputed('reminder'),
  startString: createDateComputed('startComputed'),
  endString: createDateComputed('endComputed'),
  dateString: createDateComputed('date'),
  reminderString: createDateComputed('reminderComputed'),
  startEndDateRelativeToSameVorzeichen: computed('startDateRelativeTo', 'endDateRelativeTo', 'startEndDateRelativeToSameReferenz', function(){
    return this.get('startEndDateRelativeToSameReferenz') && ((this.get('startDateRelativeTo').startsWith("before") && this.get('endDateRelativeTo').startsWith("before")) || (this.get('startDateRelativeTo').startsWith("after") && this.get('endDateRelativeTo').startsWith("after")))
  }),
  startEndDateRelativeToSameReferenz: computed('startDateRelativeTo', 'endDateRelativeTo', function(){
    return (this.get('startDateRelativeTo').endsWith("StartPeriode") && this.get('endDateRelativeTo').endsWith("StartPeriode")) || (this.get('startDateRelativeTo').endsWith("EndPeriode") && this.get('endDateRelativeTo').endsWith("EndPeriode")) || (this.get('startDateRelativeTo').endsWith("StartAnmeldung") && this.get('endDateRelativeTo').endsWith("StartAnmeldung")) || (this.get('startDateRelativeTo').endsWith("EndAnmeldung") && this.get('endDateRelativeTo').endsWith("EndAnmeldung"))
  }),
  startUpdate: createDateUpdate('start'),
  startProcessUpdate: createProcessDateUpdate('start'),
  endUpdate: createDateUpdate('end'),
  endProcessUpdate: createProcessDateUpdate('end'),
  reminderUpdate: createDateUpdate('reminder'),
  reminderProcessUpdate: createProcessDateUpdate('reminder'),
  parent: computed('term.processStepsOrderd.@each.{position,type}', 'position', function(){
    const pos = this.get('position')
    const headers = this.get('term.processStepsOrderd').filter(p => p.get('type') === 'header' && p.get('position') < pos)
    if(headers){
      return headers.get('lastObject')
    }else{
      return null
    }
  }),
  replacedUrl: computed('url', 'term.id', function(){

    let sentence = this.get('url')
    let data = {term: this.get('term.id')}
    let placeholders = sentence.match(/\$(.*?)\$/g)
    if(placeholders){
      placeholders.forEach(function(placeholder){
        const phText = placeholder.substring(1,placeholder.length - 1);
        if(data[phText]){
          sentence = sentence.replace(placeholder,data[phText])
        }
      })
    }

    return sentence
  }),
  rearange: task(function* (position) {
    const steps = yield this.get('term.processStepsOrderd')
    if(position === 0){
      this.set('position', steps.get('firstObject.position')-100)
    }else if(position === steps.get('length')){
      this.set('position', steps.get('lastObject.position')+100)
    }else{
      this.set('position', (steps.objectAt(position-1).get('position')+steps.objectAt(position).get('position'))/2)
    }
  }),
});
