import DS from 'ember-data';
import AutoSave from '../mixins/auto-save-model';
import IndicateSaveing from '../mixins/indicate-saving-model';
import { alias } from '@ember/object/computed';
import {
    computed
  } from '@ember/object';
  import {
    task, waitForProperty
  } from 'ember-concurrency';
  import {
    inject
  } from '@ember/service';

export default DS.Model.extend(AutoSave, IndicateSaveing, {
    apiFunctions: inject(),
    event: DS.belongsTo('event', { async: true, inverse: 'anmeldungen' }),
    termAnmeldung: DS.belongsTo('term-anmeldung', { async: true, inverse: 'anmeldungen' }),
    anmerkungen: DS.attr('string'),
    anmeldezeitpunkt: DS.attr('fire-date'),
    _maxNOfEvents: DS.attr('number'),
    _priority: DS.belongsTo('anmeldung-priority', { async: true}),
    _partnerWish: DS.attr('string'),
    _random: DS.attr('number'),
    niveauEinschaetzung: DS.attr('string'),
    niveauStand: DS.attr('string'),
    criteriaCheck: DS.attr('string'),
    criteriaCheckDescription: DS.attr('string'),
    statusVO: DS.attr('string'),
    _status: DS.attr('string', { defaultValue: 'toBeDetermind' }), // toBeDetermind, included, excluded
    _optimizerTempStatus: DS.attr('string'),
    _absageGrund: DS.attr('string'),
    _lock: DS.attr('boolean'),
    lockReason: DS.attr('string'),
    notiz: DS.attr('string'),
    niveauTodo: DS.attr('string'), //Maybe, Okay
    niveauTodoStatus: DS.attr('string'), //Waiting
    todos: DS.hasMany('todos', { async: true, inverse: 'anmeldung' }),
    toLate: computed('anmeldezeitpunkt', 'event.termAnmeldeschluss', function(){
        if(!this.get('event.termAnmeldeschluss')) return false
        if(this.get('event.termAnmeldeschluss').getTime() === 0) return false
        return this.get('event.termAnmeldeschluss') < this.get('anmeldezeitpunkt');
    }),
    statusOhneToLate: computed('_status', 'termAnmeldung.hasMaxNOfEvents', 'event.status', function(){
      if(this.get('event.status') == 'canceled'){
        return 'excluded';
      }else if(this.get('_status') != 'toBeDetermind'){
        return this.get('_status');
      }else{
        if(this.get('termAnmeldung.hasMaxNOfEvents')){
          return 'excluded';
        }else{
          return 'toBeDetermind';
        }
      }
    }),
    status: computed('statusOhneToLate', 'event.spacesLeftOhneToLate', 'toLate', {
      get() {
        return this.getStatus()
      },
      set(key, value) {
        if(!this.get('lock')){
          this.set('_status',  value);
          return this.getStatus()
        }else{
          return this.get('status')
        }
      }
    }),
    getStatus(){
      if(this.get('statusOhneToLate') === 'toBeDetermind' && this.get('event.spacesLeftOhneToLate') === 0){
        return 'excluded';
      }else{
        return this.get('statusOhneToLate')
      }
    },
    optimizerTempStatus: computed('_optimizerTempStatus', 'status', {
      get(){
        if(!this.get('_optimizerTempStatus') || this.get('_optimizerTempStatus') === ''){
          return this.get('status') === 'included'
        }else{
          return this.get('_optimizerTempStatus') === 'included'
        }
      },
      set(key, value) {
        if(value === false){
          this.set('_optimizerTempStatus', 'toBeDetermind')
          return value
        }else if(value){
          this.set('_optimizerTempStatus', 'included')
          return value
        }else{
          this.set('_optimizerTempStatus', null)
          return this.get('status') === 'included'
        }

      }
    }),
    tempStatusMinus: computed('status', 'optimizerTempStatus', function(){
      return this.get('status') === 'included' && !this.get('optimizerTempStatus')
    }),
    tempStatusPlus: computed('status', 'optimizerTempStatus', function(){
      return this.get('status') !== 'included' && this.get('optimizerTempStatus')
    }),
  absageGrund: computed('toLate','event.{status,lowestActivityLevelOfInflucdeAnmeldung}', 'status', '_absageGrund', 'termAnmeldung.{hasMaxNOfEvents,hasAtLeastOneEvent}', 'termAnmeldungHasAtLeastOneIncludedAnmeldungWithSameOrHigherPriority', 'aktivenStatus', {
    get() {
      return this.getAbsageGrund(this.get('_absageGrund'))
    },
    set(key, value) {
      if(!this.get('lock')){
        this.set('_absageGrund',  value);
        return this.getAbsageGrund(value)
      }else{
        return this.get('absageGrund');
      }
    }
  }),
  getAbsageGrund(_absageGrund){
    if(this.get('status') != 'excluded'){
      return _absageGrund
    }else{
      if(this.get('event.status') == 'canceled'){
        return 'cancellation';
      }else if (_absageGrund){
        return _absageGrund
      }else if(this.get('toLate')){
          return 'toLate'
      }else if(!this.get('termAnmeldungHasAtLeastOneIncludedAnmeldungWithSameOrHigherPriority')){
        if(this.get('aktivenStatus') > this.get('event.lowestActivityLevelOfInflucdeAnmeldung')){
          return 'circumstances-luck'
        }else{
          return 'luck'
        }
      }else if(this.get('termAnmeldung.hasMaxNOfEvents')){
        return 'wish-maxnofevents';
      }else if(this.get('termAnmeldung.hasAtLeastOneEvent')){
        return 'cours';
      }else{
        return "";
      }
    }
  },
  isWish: computed('absageGrund', function(){
    return this.get('absageGrund') === 'wish'
  }),
  isLuck: computed('absageGrund', function(){
    return this.get('absageGrund') === 'circumstances-luck' || this.get('absageGrund') === 'luck'
  }),
  lock: computed('_lock', {
    get() {
      return this.get('_lock')
    },
    set(key, value) {
      this.set('_lock',  value);
      if(value){
        if(this.get('status') !== this.get('_status')){
          this.set('status', this.get('status'))
        }
        if(this.get('absageGrund') !== this.get('_absageGrund')){
          this.set('absageGrund', this.get('absageGrund'))
        }
      }else{
        this.set('lockReason', null)
      }
      return value;
    }
  }),
  userPriority: computed('_priority.priority', function(){
    const priority = this.get('_priority.priority')
    if(priority){
      return priority
    }else{
      return 1
    }
  }),
  partnerWish: alias('_partnerWish'),
  runde: computed('_priority.round', function(){
    const round = this.get('_priority.round')
    if(round){
      return round
    }else{
      return 1
    }
  }),
  termAnmeldungHasAtLeastOneIncludedAnmeldungWithSameOrHigherPriority: computed('termAnmeldung.includedAnmeldungWithHighestPriority.{runde,userPriority}', 'userPriority', 'runde', function(){
    const includeAnmeldung = this.get('termAnmeldung.includedAnmeldungWithHighestPriority')
    if(includeAnmeldung){
      return includeAnmeldung.get('runde') < this.get('runde') || (includeAnmeldung.get('runde')===this.get('runde') && includeAnmeldung.get('userPriority')<=this.get('userPriority'))
    }else{
      return false
    }
  }),
  statusPriority: computed('status', function(){
    const order = ['included', 'toBeDetermind', 'excluded'];
    return order.indexOf(this.get('status'));
  }),
  statusPlusGrund: computed('status', 'absageGrund', 'niveauTodoAnswer', {
    get() {
      if(this.get('absageGrund')){
          return this.get('status')+'-'+this.get('absageGrund');
      }else{
          return this.get('status');
      }
    },
    set(key, value) {
      let [status, absageGrund] = value.split(/-(.*)/s);
      this.set('status', status);
      if(absageGrund && absageGrund !== ''){
        this.set('absageGrund',  absageGrund);
      }else{
        if(status === 'included' && this.get('niveauTodoAnswer') === 'Maybe'){
          this.set('absageGrund', 'temporary')
          return 'included-temporary'
        }else{
          this.set('absageGrund', null);
        }
      }
      return value;
    }
  }),
  toConfirm: computed('statusVO', 'status', function(){
    return this.get('status') !== this.get('statusVO')
  }),
  isConfirmed: computed('statusVO', function(){
    return this.get('statusVO') === 'included'
  }),
  weightedAnmeldung: alias('termAnmeldung.weightedAnmeldung'),
  weightedAnmeldungNotIncluded: alias('termAnmeldung.weightedAnmeldungNotIncluded'),
  weightedAnmeldungOneEventPerTeilnehmer: alias('termAnmeldung.weightedAnmeldungOneEventPerTeilnehmer'),
  termAnmeldungID: alias('termAnmeldung.id'),
  gender: alias('termAnmeldung.gender'),
  vorname: alias('termAnmeldung.vorname'),
  nachname: alias('termAnmeldung.nachname'),
  email: alias('termAnmeldung.email'),
  emailHref: alias('termAnmeldung.emailHref'),
  latestNiveau: alias('termAnmeldung.latestNiveau'),
  isPrioFromLastTerm: alias('termAnmeldung.isPrioFromLastTerm'),
  aktiv: alias('termAnmeldung.aktiv'),
  hasMaxNOfEvents: alias('termAnmeldung.hasMaxNOfEvents'),
  hasMaxNOfEbentsForSorting: computed('hasMaxNOfEvents', 'status', function(){
    if(this.get('status') === 'included') return 0; //Sofern die Anmeldung bestätigt ist, ist die es für die Sortierung nicht relevant, ob die maximale Kursanzahl erreicht ist
    return this.get('hasMaxNOfEvents')
  }),
  lengthIncludedAnmeldungen: alias('termAnmeldung.lengthIncludedAnmeldungen'),
  isMitglied: alias('termAnmeldung.isMitglied'),
  aktivenStatus: alias('termAnmeldung.aktivenStatus'),
  termAnmeldungRandom: alias('termAnmeldung.random'),
  eventID: alias('event.id'),
  eventTitel: alias('event.titel'),
  random: computed('_random', 'isLoaded', function(){
    if(this.get('isLoaded')){
      if(!this.get('_random')){
        this.set('_random', Math.random())
      }
      return this.get('_random')
    }
    return 0
  }),
  isNiveauTodo: computed('niveauTodo', 'latestNiveau', 'event.zugelasseneEinschaetzungen', 'criteriaCheck', function(){
    if(this.get('criteriaCheck') === 'notOkay' || this.get('criteriaCheck') === 'undetermined' || this.get('criteriaCheck') === 'notOkayOldEinschaetzung'  || this.get('criteriaCheck') === 'notOkayNoEinschaetzung' ) return true
    if(this.get('niveauTodo') === 'Maybe' || this.get('niveauTodo') === 'Okay' || this.get('niveauTodo') === 'NotOkay') return true
    if(!this.get('event.zugelasseneEinschaetzungen')) return false
    const latestNiveau = (this.get('latestNiveau') && this.get('latestNiveau') !== '') ? this.get('latestNiveau') : 'none'
    return !this.get('event.zugelasseneEinschaetzungen').includes(latestNiveau)
  }),
  isOpenNiveauTodo: computed('isNiveauTodo', 'status', 'niveauTodo', function(){
    if(!this.get('isNiveauTodo')) return false
    if(this.get('status') !== 'toBeDetermind') return false
    if(this.get('niveauTodo') === 'Maybe' || this.get('niveauTodo') === 'Okay' || this.get('niveauTodo') === 'NotOkay') return false
    return true
  }),
  niveauTodoAnswer: computed('isNiveauTodo', 'statusPlusGrund', 'niveauTodo', function(){
    if(!this.get('isNiveauTodo')) return ''
    if(this.get('niveauTodo') && this.get('niveauTodo') !== '') return this.get('niveauTodo')
    if(this.get('statusPlusGrund') === 'included') return 'Okay'
    if(this.get('statusPlusGrund') === 'included-successors') return 'Okay'
    if(this.get('statusPlusGrund') === 'included-temporary') return 'Maybe'
    if(this.get('statusPlusGrund') === 'excluded-niveau') return 'NotOkay'
    if(this.get('statusPlusGrund') === 'excluded-noniveau') return 'NotOkay'
    return 'Open'
  }),
  isNiveauTodoMaybe: computed('niveauTodoAnswer', function(){
    return this.get('niveauTodoAnswer') === 'Maybe'
  }),
  confirmAnmeldung: task({
    status: '',
    message: '',
    progress: '',
    setMessage(message) {
        this.set('message', message)
        this.set('progress', 'readyToConfirm')
    },
    *perform(withMessage) {
        let anmeldung = this.get('context');
        const status = anmeldung.get('status')
        this.set('status', status)
        this.set('progress', 'started')
        if(status === 'excluded' || anmeldung.get('absageGrund') === 'temporary' || anmeldung.get('absageGrund') === 'successors' || withMessage){
            this.set('progress', 'waitingForMessage')
            yield waitForProperty(this, 'progress', 'readyToConfirm')
            this.set('progress', 'confirming')
        }
        this.set('progress', 'confirming')
        yield anmeldung.get('apiFunctions').changeRegistrationForCours.perform(anmeldung.get('id'), anmeldung.get('event.id'), status, this.get('message'))
        if(status === 'excluded' || status === 'included'){
          anmeldung.set('lock', true)
          if(anmeldung.get('lockReason')){
            anmeldung.set('lockReason', anmeldung.get('lockReason')+' | Teilnehmer informiert')
          }else{
            anmeldung.set('lockReason', 'Teilnehmer informiert')
          }
        }
        this.set('progress', 'done')
    },
  }).drop(),
  isConfirmAnmeldungRunning: alias('confirmAnmeldung.isRunning'),
  toggleLockAnmeldung: task({
    status: '',
    message: '',
    progress: '',
    setMessage(message) {
      this.set('context.lockReason', message)
        this.set('progress', 'readyToConfirm')
    },
    *perform() {
        let anmeldung = this.get('context');
        this.set('progress', 'started')
        if(!anmeldung.get('lock')){
            this.set('progress', 'waitingForMessage')
            yield waitForProperty(this, 'progress', 'readyToConfirm')
            this.set('progress', 'confirming')
            anmeldung.set('lock', true)
        }else{
          this.set('progress', 'confirming')
          anmeldung.set('lock', false)
        }
        this.set('progress', 'done')
    },
  }).drop(),
  isToggleLockAnmeldungRunning: alias('toggleLockAnmeldung.isRunning'),
  setNiveauNotOkay: task(function* () {
    if(this.get('niveauTodoAnswer') === 'NotOkay'){
      if(this.get('statusPlusGrund') === 'excluded-niveau'){
        this.set('statusPlusGrund', 'toBeDetermind')
      }
      this.set('niveauTodo', '')
    }else{
      this.set('statusPlusGrund', 'excluded-niveau')
      this.set('niveauTodo', 'NotOkay')
    }
    yield true
  }),
  setNiveauMaybe: task(function* () {
    if(this.get('niveauTodoAnswer') === 'Maybe'){
      this.set('niveauTodo', '')
    }else{
      if(this.get('statusPlusGrund') === 'excluded-niveau'){
        this.set('statusPlusGrund', 'toBeDetermind')
      }
      this.set('niveauTodo', 'Maybe')
    }
    yield true
  }),
  setNiveauOkay: task(function* () {
    if(this.get('niveauTodoAnswer') === 'Okay'){
      this.set('niveauTodo', '')
    }else{
      if(this.get('statusPlusGrund') === 'excluded-niveau'){
        this.set('statusPlusGrund', 'toBeDetermind')
      }
      this.set('niveauTodo', 'Okay')
    }
    yield true
  }),
  todosWithTeilnehmer: computed('todos.[]', 'termAnmeldung.todos.[]', function(){
    let out = [];
    out = out.pushObjects(this.get('todos').toArray())
    out = out.pushObjects(this.get('termAnmeldung.todos').toArray())
    return out
  }),
  hasNiveauEinschaetzung: computed('niveauEinschaetzung', function(){
    return (this.get('niveauEinschaetzung') && this.get('niveauEinschaetzung') !== "") ? true : false
  }),
  termID: alias('termAnmeldung.termID'),
  termName: alias('termAnmeldung.termName'),
  onHold: alias('event.onHold'),
  datum: alias('event.datum')
});
