import DS from 'ember-data';
import { filterBy, sort, alias, gt, union } from '@ember/object/computed';
import {
  computed
} from '@ember/object';
import AutoSave from '../mixins/auto-save-model';
import IndicateSaveing from '../mixins/indicate-saving-model';
import {
  inject
} from '@ember/service';
import {
  task
} from 'ember-concurrency';

export default DS.Model.extend(AutoSave, IndicateSaveing, {
  apiFunctions: inject(),
  automatedAllocation: inject(),
  name: DS.attr('string'),
  archived: DS.attr('boolean'),
  anmeldestart: DS.attr('fire-date'),
  anmeldeschluss: DS.attr('fire-date'),
  von: DS.attr('fire-date'),
  bis: DS.attr('fire-date'),
  durchMin: DS.attr('number'),
  lastTerm: DS.belongsTo('term', { async: true, inverse: null }),
  year: DS.belongsTo('year', { async: true, inverse: 'terms' }),
  termType: DS.belongsTo('term-type', { async: true, inverse: 'terms' }),
  events: DS.hasMany('event', { async: true, inverse: 'term' }),
  termAnmeldungen: DS.hasMany('term-anmeldung', { async: true, inverse: 'term' }),
  todos: DS.hasMany('todos', { async: true, inverse: 'term' }),
  processSteps: DS.hasMany('process-step', { async: true, inverse: 'term' }),
  processStepsSorting: Object.freeze(['position']),
  processStepsOrderd: sort('processSteps', 'processStepsSorting'),
  processStepsHighestPosition: alias('processStepsOrderd.lastObject.position'),
  anmeldungen: computed('events.@each.anmeldungen', function(){
    return this.get('events').reduce((previousValue, item) => previousValue.pushObjects(item.get('anmeldungen').map(item => item)), []);
  }),
  sortedAnmeldungen: sort('anmeldungen', 'automatedAllocation.prioSort'),
  toBeDetermindAnmeldungen: filterBy('anmeldungen', 'status', 'toBeDetermind'),
  includedAnmeldungen: filterBy('anmeldungen', 'status', 'included'),
  absagen: filterBy('anmeldungen', 'status', 'excluded'),
  teilnehmerWithEvent: computed('termAnmeldungen.@each.lengthIncludedAnmeldungen', function(){
    return this.get('termAnmeldungen').filter(item => item.get('lengthIncludedAnmeldungen') > 0);
  }),
  prospectWithEvent: filterBy('teilnehmerWithEvent', 'isMitglied', false),
  teilnehmerToBeDetermind: computed('termAnmeldungen.@each.{lengthIncludedAnmeldungen,lengthToBeDetermindAnmeldungen}', function(){
      return this.get('termAnmeldungen').filter(item => item.get('lengthIncludedAnmeldungen') == 0 && item.get('lengthToBeDetermindAnmeldungen') > 0);
  }),
  teilnehmerWithoutEvent: computed('termAnmeldungen.@each.{lengthIncludedAnmeldungen,lengthToBeDetermindAnmeldungen,lengthExcludedAnmeldungenWithoutWish}', function(){
      return this.get('termAnmeldungen').filter(item => item.get('lengthIncludedAnmeldungen') == 0 && item.get('lengthToBeDetermindAnmeldungen') == 0 && item.get('lengthExcludedAnmeldungenWithoutWish') != 0);
  }),
  teilnehmerWithoutEventOrMaybeWithout: union('teilnehmerToBeDetermind', 'teilnehmerWithoutEvent'),
  teilnehmerWithInterestForMoreEvents: filterBy('termAnmeldungen', 'hasMaxNOfEvents', false),
  teilnehmerWithEventAtTheSameDay: filterBy('termAnmeldungen', 'eventAtTheSameDay'),
  hasTeilnehmerWithEventAtTheSameDay: gt('teilnehmerWithEventAtTheSameDay.length', 0),
  pTeilnehmerWithoutEvent: computed('teilnehmerWithoutEvent.length', 'extendedTeilnehmerForTerm.length', function(){
      return Math.round(this.teilnehmerWithoutEvent.length/this.extendedTeilnehmerForTerm.length*100);
  }),
  openNiveauTodoAnmeldungenLength: computed('termAnmeldungen.@each.openNiveauTodoAnmeldungenLength', function(){
    return this.get('termAnmeldungen').reduce(function(summation, current) {
      return summation + current.get('openNiveauTodoAnmeldungenLength');
    }, 0)
  }),
  syncTerm: task(function* () {
    yield this.get('apiFunctions').loadTermsAndTeilnehmer.perform()
    return yield this.get('apiFunctions').loadTerm.perform(this.get('id'));
  }).drop(),
  todosAlle: computed('todos.[]', 'events.@each.todosWithAnmeldungen', 'termAnmeldungen.@each.todos', function(){
    let out = [];
    out = out.pushObjects(this.get('todos').toArray())
    this.get('events').forEach(i => {
        out = out.pushObjects(i.get('todosWithAnmeldungen').toArray())
    })
    this.get('termAnmeldungen').forEach(i => {
      out = out.pushObjects(i.get('todos').toArray())
    })
    return out
  }),
  teilnehmerWithTodo: filterBy('termAnmeldungen', 'todoStatusTodoOrWaiting', true),
  notCanceldEvents: filterBy('events', 'isCanceled', false),
  trainer: computed('events.@each.trainer', function(){
    return this.get('events').reduce(function(a, e) {
        a.pushObjects(e.get('trainer').toArray())
      return a
    }, []).uniqBy('id')

  }),

});
