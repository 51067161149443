import Controller from '@ember/controller';
import {
  task,
  all
} from 'ember-concurrency';
import {
  inject as service
} from '@ember/service';
import {
  computed
} from '@ember/object';

export default Controller.extend({
    automatedAllocation: service(),
    filterAnmeldungen: '',
    queryParams: ['queryAnmeldungen', 'filterAnmeldungen'],
    searchProperties: ['vorname', 'nachname', 'email', 'absageGrund'],
    filteredAnmeldungen: computed('filterAnmeldungen', 'model.anmeldungen.value.@each.statusPlusGrund', 'model.term.value.{toBeDetermindAnmeldungen,includedAnmeldungen,absagen}', function(){
      if(this.get('model.term.value')){
        if(!this.get('filterAnmeldungen') || this.get('filterAnmeldungen') === ''){
          return this.get('model.anmeldungen.value')
        }else if(this.get('filterAnmeldungen') === 'toBeDetermind'){
          return this.get('model.term.value.toBeDetermindAnmeldungen')
        }else if(this.get('filterAnmeldungen') === 'included'){
          return this.get('model.term.value.includedAnmeldungen')
        }else if(this.get('filterAnmeldungen') === 'excluded'){
          return this.get('model.term.value.absagen')
        }else{
          return this.get('model.anmeldungen.value').filterBy('statusPlusGrund', this.get('filterAnmeldungen'))
        }
      }else{
        return false
      }
    }),
    actions:{
      extendedSortKey(key){
        if(key == 'kurs:desc'){
          return ['lengthIncludedAnmeldungen:desc', 'lengthToBeDetermindAnmeldungen:desc'];
        }else if(key == 'kurs:asc'){
          return ['lengthIncludedAnmeldungen:asc', 'lengthToBeDetermindAnmeldungen:asc'];
        }else if(key == 'statusAndPrio:desc'){
          return this.get('automatedAllocation.prioSort')
        }else if(key == 'statusAndPrio:asc'){
          return this.get('automatedAllocation.prioSort')
        }else{
          return key;
        }
      }
    }
});
