import Component from '@ember/component';
import { computed }  from '@ember/object';
import { filterBy, alias } from '@ember/object/computed';

export default Component.extend({
  tagName:"tr",
  classNames: ['md-row'],
  canceldEvents: filterBy('events', 'status', 'canceled'),
  nOfCanceldEvents: alias('canceldEvents.length'),
  openEvents: filterBy('events', 'isOpenAndDoesntHaveConfirmations', true),
  nOfOpenEvents: alias('openEvents.length'),
  notCanceldEvents: computed('events.@each.status', function(){
    return this.get('events').rejectBy('status', 'canceled');
  }),
  nOfNotCanceldEvents: alias('notCanceldEvents.length'),
  notCanceldAndOpenEvents: filterBy('notCanceldEvents', 'registrationClosed', false),
  haveAtleastOneConfirmation: filterBy('events', 'hasAtleastOneConfirmation', true),
  nOfHaveAtleastOneConfirmation: alias('haveAtleastOneConfirmation.length'),
  nOfSpaces: computed('notCanceldEvents.@each.maxpersonen', function(){
    return this.get('notCanceldEvents').reduce(function(previousValue, item){
      return previousValue + item.get('maxpersonen');
    }, 0)
  }),
  anmeldungen: computed('events.@each.anmeldungen', function(){
    return this.get('events').reduce((previousValue, item) => previousValue.pushObjects(item.get('anmeldungen').map(item => item)), []);
  }),
  nOfAnmeldungen: alias('anmeldungen.length'),
  nOfWeightedAnmeldungen: computed('anmeldungen.@each.weightedAnmeldung', function(){
    return Math.round(this.get('anmeldungen').reduce((previousValue, item) => previousValue+item.get('weightedAnmeldung'), 0));
  }),
  nOfAnmeldungenOneEventPerTeilnehmer: computed('anmeldungen.@each.weightedAnmeldungOneEventPerTeilnehmer', function(){
    return Math.round(this.get('anmeldungen').reduce((previousValue, item) => previousValue+item.get('weightedAnmeldungOneEventPerTeilnehmer'), 0));
  }),
  nOfAktivenAnmeldungenOneEventPerTeilnehmer: computed('anmeldungen.@each.{weightedAnmeldungOneEventPerTeilnehmer,aktiv}', function(){
    return Math.round(this.get('anmeldungen').reduce((previousValue, item) => previousValue+(item.get('aktiv') ? item.get('weightedAnmeldungOneEventPerTeilnehmer') : 0), 0));
  }),
  teilnehmer: computed('anmeldungen.@each.termAnmeldung', function(){
    let teilnehmer = this.get('anmeldungen').getEach('termAnmeldung');
    teilnehmer = teilnehmer.uniqBy('id');
    return teilnehmer.filter(item => item.get('lengthAnmeldungen') > 0);
  }),
  teilnehmerHash: computed('teilnehmer.@each.teilnehmerID', function(){
    return this.get('teilnehmer').mapBy('teilnehmerID').toArray().join(',');
  }),
  nOfTeilnehmer: alias('teilnehmer.length'),
  weightedAnmeldungenProTeilnehmer: computed('nOfWeightedAnmeldungen', 'nOfTeilnehmer', function(){
    return Math.round(this.get('nOfWeightedAnmeldungen')/this.get('nOfTeilnehmer')*100)/100;
  }),
  absagen: filterBy('anmeldungen', 'status', 'excluded'),
  absagenWithoutWishAndError: computed('absagen.@each.absageGrund', function(){
    return this.get('absagen').rejectBy('absageGrund', 'wish').rejectBy('absageGrund', 'wish-maxnofevents').rejectBy('absageGrund', 'error');
  }),
  nOfAbsagenWithoutWishAndError: alias('absagenWithoutWishAndError.length'),
  absagenWithoutWishErrorAndNiveau: computed('absagenWithoutWishAndError.@each.absageGrund', function(){
    return this.get('absagenWithoutWishAndError').rejectBy('absageGrund', 'niveau').rejectBy('absageGrund', 'noniveau');
  }),
  nOfAbsagenWithoutWishErrorAndNiveau: alias('absagenWithoutWishErrorAndNiveau.length'),
  missingSpaces: computed('events.@each.missingSpaces', function(){
    return this.get('events').reduce((previousValue, item) => previousValue+item.get('missingSpaces'), 0)
  }),
  nOfSpacesLeft: computed('notCanceldEvents.@each.spacesLeft', function(){
    return this.get('notCanceldEvents').reduce(function(previousValue, item){
      return previousValue + item.get('spacesLeft');
    }, 0)
  }),
  nOfSpacesLeftWithoutCandidate: computed('notCanceldEvents.@each.spacesLeftWithoutCandidate', function(){
    return this.get('notCanceldEvents').reduce(function(previousValue, item){
      return previousValue + item.get('spacesLeftWithoutCandidate');
    }, 0)
  }),
  nOfSpacesLeftWithoutCandidateInNonClosedEvents: computed('notCanceldAndOpenEvents.@each.spacesLeftWithoutCandidate', function(){
    return this.get('notCanceldAndOpenEvents').reduce(function(previousValue, item){
      return previousValue + item.get('spacesLeftWithoutCandidate');
    }, 0)
  }),
  teilnehmerToBeDetermind: computed('teilnehmer.@each.{lengthIncludedAnmeldungen,lengthToBeDetermindAnmeldungen}', function(){
    return this.get('teilnehmer').filter(item => item.get('lengthIncludedAnmeldungen') == 0 && item.get('lengthToBeDetermindAnmeldungen') > 0);
  }),
  nOfTeilnehmerToBeDetermind: alias('teilnehmerToBeDetermind.length'),
  teilnehmerWithoutEvent: computed('teilnehmer.@each.{lengthIncludedAnmeldungen,lengthToBeDetermindAnmeldungen,lengthExcludedAnmeldungenWithoutWish}', function(){
    return this.get('teilnehmer').filter(item => item.get('lengthIncludedAnmeldungen') == 0 && item.get('lengthToBeDetermindAnmeldungen') == 0 && item.get('lengthExcludedAnmeldungenWithoutWish') != 0);
  }),
  nOfTeilnehmerWithoutEvent: alias('teilnehmerWithoutEvent.length'),
  nOfSpacesPerTeilnehmer: computed('nOfSpaces', 'nOfTeilnehmer', function(){
    return Math.round(this.get('nOfSpaces')/this.get('nOfTeilnehmer')*100)/100;
  }),
  pOfSpacesLeft: computed('nOfSpacesLeft', 'nOfSpaces', function(){
    return Math.round(this.nOfSpacesLeft/this.nOfSpaces*100);
  }),
  pOfAbsagenWithoutWishAndError: computed('nOfAbsagenWithoutWishAndError', 'nOfAnmeldungen', function(){
    return Math.round(this.nOfAbsagenWithoutWishAndError/this.nOfAnmeldungen*100);
  }),
  pOfAbsagenWithoutWishErrorAndNiveau: computed('nOfAbsagenWithoutWishErrorAndNiveau', 'nOfAnmeldungen', function(){
    return Math.round(this.nOfAbsagenWithoutWishErrorAndNiveau/this.nOfAnmeldungen*100);
  }),
  pOfTeilnehmerWithoutEvent: computed('nOfTeilnehmerWithoutEvent', 'nOfTeilnehmer', function(){
    return Math.round(this.nOfTeilnehmerWithoutEvent/this.nOfTeilnehmer*100);
  }),
  pOfWeightedAnmeldungen: computed('nOfWeightedAnmeldungen', 'nOfSpaces', function(){
    return Math.round(this.nOfWeightedAnmeldungen/this.nOfSpaces*100);
  }),
  pOfAktivenAnmeldungenOneEventPerTeilnehmer: computed('nOfAnmeldungenOneEventPerTeilnehmer', 'nOfAktivenAnmeldungenOneEventPerTeilnehmer', function(){
    return Math.round(this.nOfAktivenAnmeldungenOneEventPerTeilnehmer/this.nOfAnmeldungenOneEventPerTeilnehmer*100);
  }),
  nOfTrainer: computed('notCanceldEvents.@each.trainer', function(){
    let trainer = this.get('notCanceldEvents').reduce((previousValue, item) => previousValue.pushObjects(item.get('trainer').map(item => item)), []);
    trainer = trainer.uniqBy('id');
    return trainer.get('length')
  }),
  trainerHash: computed('notCanceldEvents.@each.trainer', function(){
    let trainer = this.get('notCanceldEvents').reduce((previousValue, item) => previousValue.pushObjects(item.get('trainer').map(item => item)), []);
    return trainer.mapBy('id').toArray().join(',');
  }),
  nOfEventsPerTrainer: computed('nOfTrainer', 'nOfNotCanceldEvents', function(){
    return Math.round(this.get('nOfNotCanceldEvents')/this.get('nOfTrainer')*100)/100;
  }),
  final: computed('term.bis', function(){
    return this.get('term.bis') < new Date() ? 'Ja' : 'Nein';
  }),
  copyString: computed('term.name', 'final', 'term.year.name', 'term.termType.name', 'category', 'nOfNotCanceldEvents', 'nOfSpaces', 'nOfWeightedAnmeldungen', 'nOfSpacesLeft', 'nOfTeilnehmer', 'nOfTeilnehmerWithoutEvent', 'nOfTrainer', 'teilnehmerHash', 'trainerHash', function(){
    return this.get('term.name') + '\t' + this.get('final') + '\t' + this.get('term.year.name') + '\t' + this.get('term.termType.name') + '\t' + this.get('category') + '\t' + this.get('nOfNotCanceldEvents') + '\t' + this.get('nOfSpaces') + '\t' + this.get('nOfWeightedAnmeldungen') + '\t' + this.get('nOfSpacesLeft') + '\t' + this.get('nOfTeilnehmer') + '\t' + this.get('nOfTeilnehmerWithoutEvent') + '\t' + this.get('nOfTrainer') + '\t' + this.get('teilnehmerHash') + '\t' + this.get('trainerHash')
  })
});
