import Controller from '@ember/controller';
import {
  task,
  all
} from 'ember-concurrency';
import {
  inject
} from '@ember/service';
import {
  computed
} from '@ember/object';


export default Controller.extend({
  store: inject(),
  termsWithSteps: computed('model.terms.value.@each.processSteps', 'model.term.value', function(){
    if(!this.get('model.terms.value') || !this.get('model.term.value')) return 0
    const cterm = this.get('model.term.value')
    return this.get('model.terms.value').filter(t => {
      if(cterm.get('id') === t.get('id')) return false
      return t.hasMany('processSteps').ids().length > 0
    })
  }),
  addProcessStep: task(function* () {
    const term = yield this.get('model.term')
    yield term.get('processSteps')
    const position = term.get('processStepsHighestPosition')+100
    const record = this.get('store').createRecord('process-step', {term, position})
    yield record.save()
    yield term.save()
    this.transitionToRoute('term.term.process-steps.step', term.get('id'), record.get('id'))
  }),
  import: task(function* (sourceTerm) {
    const term = yield this.get('model.term')
    sourceTerm = yield sourceTerm
    const steps = yield sourceTerm.get('processSteps')
    const store = this.get('store')
    const nSteps = steps.map(s => {
      const r = {
        term,
        name: s.name,
        text: s.text,
        textSnippet: s.textSnippet,
        type: s.type,
        url: s.url,
        instructionUrl: s.instructionUrl,
        position: s.position,
        start: s.start,
        end: s.end,
        dateRequiered: s.dateRequiered,
        reminder: s.reminder,
        startDateType: s.startDateType,
        endDateType: s.endDateType,
        reminderDateType: s.reminderDateType,
        startDateRelativeTo: s.startDateRelativeTo,
        endDateRelativeTo: s.endDateRelativeTo,
        reminderDateRelativeTo: s.reminderDateRelativeTo,
        startDateOffset: s.startDateOffset,
        endDateOffset: s.endDateOffset,
        reminderDateOffset: s.reminderDateOffset,
      }
      return store.createRecord('process-step', r)
    })
    yield all(nSteps.map(s => {
      s.get('updateDates').perform()
      return s.save()
    }))
    yield term.save()
  }),
});
